import React, { useEffect, useState} from 'react';



const NosProgrammes = () => {

    const [programmes, setProgrammes] = useState([]);
    useEffect(() => {
             fetch('https://api.leizee.com/programme', {
                headers: {
                    'Authorization': 'Basic ' + btoa('kotimo-agence-dewey:i00a1n41wrf3zxn1b347y5d87gz5f9'),
                    'Mode': 'no-cors',
                }
            })
                .then((res) => res.json())
                .then((data) => setProgrammes(data));

    },[programmes]);

console.log(programmes);
    if(programmes.length < 1) return <div className="loader-ville"><p>Chargement ...</p></div>

    return(
        <div className="programmesh pt-6 xl:pt-20">
            <div className="programmesh-display mb-10">
                <div></div>
                {programmes?.resultat?.slice(0, 3).map((programme) => (
                    <a key={programme.id} href={`/programme/${programme.id}`}>
                        <div className="programmesh-container">
                            <img src={programme.url_vue_principale}/>

                            <div className="programmesh-container-t p-6">
                                <p className="programmesh-container-t-title font-bold uppercase">{programme.nom}</p>
                                <p className="programmesh-container-t-ville">{programme.ville}</p>
                                <p className="programmesh-container-t-genre">{programme.genres_lot}</p>
                                <p className="mb-3 programmesh-container-t-nb-biens">{programme.nb_lots_total} bien{programme.nb_lots_total < 2 || 's'} dont {programme.nb_lots_dispos} disponible{programme.nb_lots_dispos < 2 || 's'}</p>
                                <a className="button-v" href={`programme/${programme.id}`}>Découvrir</a>
                            </div>


                        </div>
                    </a>
                ))}
            <div></div>
            </div>

            <div className="flex justify-center">
                <a className="button-v" href="/programmes">Découvrir tous nos biens</a>
            </div>

        </div>
    )
}

export default NosProgrammes